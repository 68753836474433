import { getSetting, updateSetting } from '@/api/member';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      settings: {}
    };
  },
  created() {
    if (this.userIsLogin) {
      getSetting(this.key).then(data => {
        if (this.getDataSuccessCallback) {
          this.getDataSuccessCallback(data);
        }
      });
    }
  },
  computed: {
    ...mapGetters(['userIsLogin']),
    noSettingYet() {
      return this.settings === false;
    }
  },
  methods: {
    updateSettings(cb, errCb, displayLoading) {
      updateSetting(
        {
          key: this.key,
          settings: this.settings
        },
        displayLoading
      )
        .then(data => {
          if (cb) {
            cb(data);
          }
        })
        .catch(err => {
          if (errCb) {
            errCb(err);
          }
        });
    }
  }
};
