import memberSettingMixin from '@/mixins/member-settings';
import { debounce } from 'lodash';

export default {
  ...memberSettingMixin,
  data() {
    return {
      ...memberSettingMixin.data(),
      fetchData: null
    };
  },
  methods: {
    ...memberSettingMixin.methods,
    getDataSuccessCallback(data) {
      this.fetchData = data;
    }
  },
  computed: {
    ...memberSettingMixin.computed,
    notification: {
      get() {
        return this.settings.notification;
      },
      set(newVal) {
        this.$set(this.settings, 'notification', newVal);
      }
    },
    noSettingYet() {
      return this.fetchData === false;
    }
  },
  watch: {
    notification: debounce(function(newVal, oldVal) {
      if (this.noSettingYet !== true && oldVal === undefined) {
        return;
      }

      if (this.noSettingYet === true || oldVal !== null) {
        this.updateSettings(null, null, false);
      }
    }, 500),
    fetchData(newVal) {
      if (newVal === false) {
        this.$set(this, 'notification', this.default);
      } else {
        this.$set(this, 'notification', newVal.notification);
      }
    }
  }
};
